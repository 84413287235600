<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
 Maldives Visa
         </h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap ">
          <div class="mx-2 text-left">
              <p class="my-8">If entering the Maldives as a tourist, you will be granted a 30-day visa upon arrival and your passport must be valid for a minimum of one month. But it would be safer if the passport is valid for more than a year. </p>

              <p class="my-8"><b> If you want to apply for a business visa: </b></p>
            <p class="my-8">
            To obtain the Business Visa, an application has to be submitted via IMUGA Portal by the sponsor and when Business Visa gets approved then the sponsor needs to pay the visa fee of MVR750 online prior to an hour of the arrival of the foreigner.<br>
The following documents has to be submitted (in the mentioned file format) to Immigration.

            </p>  
            
             <p class="my-8">
             <ol>
<li> 1.	Business Visa application form (form IM24) (PDF format) </li> 
<li> 2.	A machine readable (MRZ) Passport Bio-data page color copy of the applicant (At least 6 months validity) (PDF format) </li> 
<li> 3.	Registration copy of the applicant (PDF format) </li> 
<li> 4.	Passport size photo (JPEG format) </li> 
<li> 5.	Supporting Documents such as Qualification certificates or letter of experience (PDF format) </li> 
<li> 6.	A letter from sponsor, stating the details of stay in Maldives of the foreigner (PDF format) </li> 
<li> 7.	Visa Applicant Information Form (IM30), if applicable (PDF format) </li> 


                 </ol>
            </p>


            <p class="my-8 mb-40"> 
                For more information please click the link below:<br> <a href="https://immigration.gov.mv/business-2/">
https://immigration.gov.mv/business-2/ </a>


                </p>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "PoliciesPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
